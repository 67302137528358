<template>
  <div>
    <div v-if="loading" class="loader-container">
      <div class="loading-wrapper">
        <v-progress-linear
          value="3"
          height="3"
          indeterminate
          color="black"
        ></v-progress-linear>
      </div>
    </div>
    <div v-if="!loading">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { mapActions, mapState } from "vuex";
import { getDev, fetchClientProjects } from "@/api";
import postLogin from "@/mixins/postLogin";
import mixPanel from "@/mixins/mixPanel";
export default {
  mixins: [postLogin, mixPanel],
  props: {},
  components: {},

  data: () => ({}),

  created() {
    this.checkUserAccess();
  },
  methods: {
    ...mapActions("loaderManagement", ["setAuthenticatedLoader"]),
    ...mapActions("devDashboard", ["setTeamDetails"]),
    ...mapActions("clientDashboard", ["setAllProjects"]),
    ...mapActions("userManagement", [
      "setAutheticatedUser",
      "setUserLocation",
      "logOutUser",
      "setLinkedinLogin",
    ]),
    checkUserAccess() {
      if (localStorage.hasOwnProperty("grupa_user")) {
        this.setAuthenticatedLoader(true);
        // const user = JSON.parse(localStorage.getItem("grupa_user"));
        const user = this.currentUser;
        if (user.user_type == 1 || user.user_type == 6) {
          this.getTeamDetails();
        } else if (user.user_type == 2 || user.user_type == 5) {
          this.getMyProject();
        } else if (!user.user_type) {
          if (this.$route.name == "user_type") {
            this.setAuthenticatedLoader(false);
          } else {
            this.$router.push({ name: "user_type" });
          }
        }
      } else {
        this.setAuthenticatedLoader(false);
      }
    },
    getMyProject() {
      fetchClientProjects()
        .then((response) => {
          if (response.data.status === 1) {
            let projects = response.data.data.projects;
            if (projects.length > 0) {
              let lastProject = projects[projects.length - 1];
              this.setAllProjects(response.data.data.projects);
              this.spinner = false;
              this.$router.push({
                name: "project_workspace",
                params: { id: lastProject.id },
              });
            } else {
              this.$router.push({ name: "start_project" });
            }
          }
        })
        .catch((error) => {
          if (!error.response) {
            this.logOutUser();
            window.location.reload();
          }
        });
    },
    getTeamDetails() {
      getDev()
        .then((res) => {
          if (res.data.status == 1) {
            this.setTeamDetails(res.data);
            this.$developConsole(res.data, "login data here");
            const { user, team } = res.data.data;
            this.mixPanelTrack({
              event: "BackToDashboard",
              email: user.email,
              userType: "dev",
              teamActivationLevel: team.activation_level,
              userActivationLevel: user.activation_level,
            });
            this.teamActivePage(user, team);
          }
        })
        .catch((err) => {
          this.spinner = false;
          this.logOutUser();
          window.location.reload();
        });
    },
    setLogInUser(user) {
      let payload = {
        user,
        isAuthenticated: true,
        userType: user.user_type,
      };
      this.setAutheticatedUser(payload);
      localStorage.setItem("grupa_user", JSON.stringify(user));
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
    ...mapState("loaderManagement", ["loading"]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.loader-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-wrapper {
  width: 400px;
}
</style>
