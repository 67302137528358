var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading
      ? _c("div", { staticClass: "loader-container" }, [
          _c(
            "div",
            { staticClass: "loading-wrapper" },
            [
              _c("v-progress-linear", {
                attrs: {
                  value: "3",
                  height: "3",
                  indeterminate: "",
                  color: "black",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    !_vm.loading ? _c("div", [_vm._t("default")], 2) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }